import { AuthenticationCallbackPageComponent } from './auth-callback.page';
import { LogOutCallbackPageComponent } from './logout-callback.page';

export const containers: any[] = [
  AuthenticationCallbackPageComponent,
  LogOutCallbackPageComponent
];

export * from './auth-callback.page';
export * from './logout-callback.page';
